@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

.header .navbar {
  background-color: transparent;
  transition: background-color 0.5s ease;
}

/* Scroll yapıldığında arka plan ekleme */
.header .navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Banner */
.banner {
  height: 100vh;
  background-image: url('./assets/img/banner.jpg');
  /* Banner görseli */
  background-size: cover;
  background-position: center;
  position: relative;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 42, 92, 0.65);
  /* Opaklık seviyesi */
  z-index: 1;
}

/* Subpage Banner */
.subpage-banner {
  height: 75vh;
  background-image: url('./assets/img/subpage-banner.jpg');
  /* Banner görseli */
  background-size: cover;
  background-position: center;
  position: relative;
}

.subpage-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 42, 92, 0.65);
  /* Opaklık seviyesi */
  z-index: 1;
}

h1 {
  position: relative;
  z-index: 2;
  /* İçerik overlay'in üstünde görünsün */
  font-size: 4.5rem !important;
  font-weight: bold;
}

/* Navbar linkler için stil */
.navbar-nav .nav-link {
  color: white;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 17px !important;
}

.nav-item.active .nav-link {
  color: #34DA89;
}

.navbar-nav .nav-link:hover {
  color: #34DA89;
}

.navbar-nav .nav-link.active {
  color: #34DA89 !important;
}

h2 {
  font-size: 2rem !important;
  font-weight: bold !important;
  color: #002a5c !important;
}

p {
  margin-top: 1rem;
  font-size: 1rem;
  color: #6b7078 !important;
}

.img-fluid {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.final-content {
  background-color: #002A5C;
  padding: 65px 0;
}

/* 34da89 */

.final-header {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

.final-text {
  font-size: 1.1rem;
  color: #fff;
  margin-top: 1.5rem;
  line-height: 1.6;
}

.info-card-3 {
  background-color: #f5f5f5;
}

.card-style4 {
  box-shadow: 0px 3px 10px 0px rgba(41, 45, 194, 0.08);
  border: 1px solid #ececec !important;
  border-radius: 5px !important;
}

.section {
  padding: 65px 0;
}

.content-title {
  font-size: 3rem !important;
}

.contact-info h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.contact-info p {
  font-size: 1rem;
  color: #555;
}

.contact-info ul {
  padding-left: 0;
}

.contact-info li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.icon-background {
  background-color: rgba(52, 218, 137, .15);
  border-radius: 50%;
  padding: 1rem;
}

.header-active {
  background-color: #002A5C !important;
}

.why-us-content h3 {
  color: #002A5C;
  font-size: 22px;
  font-weight: 700;
}

.why-us-content .card-style4 {
  transition: all .3s ease;
}

.why-us-content .card-style4:hover {
  background-color: #002A5C;
}

.why-us-content .card-style4:hover h3,
.why-us-content .card-style4:hover p {
  color: #fff !important;
}

.form-label {
  color: #6b7078 !important;
  font-weight: 600;
}

.form-control {
  padding: .375rem .75rem !important;
  font-size: 1rem !important;
  color: #212529 !important;
  border: 1px solid #dee2e6 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-control::placeholder {
  color: #aaa !important;
}

.form-control:focus {
  border: 1px solid #34DA89 !important;
}

.btn-primary-v2 {
  background-color: #002A5C;
  color: #fff;
  border-radius: 5px;
  border: none !important;
}

.footer-content {
  background-color: #002A5C;
  color: #fff;
  color: #f5f5f5;
}

.btn-secondary-v2 {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 7px 21px;
  border-radius: 5px;
}

.btn-outline-secondary-v2 {
  background-color: #34DA89;
  padding: 7px 21px;
  color: #fff;
  border: 1px solid #34DA89;
  border-radius: 5px;
}

.advantages-content h5 {
  color: #002A5C;
  font-weight: 600;
}

.required {
  color: #ff7272;
}

.navbar-toggler-icon {
  background-image: url("https://img.icons8.com/ios-filled/50/ffffff/menu--v6.png") !important;
}

.navbar-toggler {
  border: 1px solid #fff !important;
}

.partners-content {
  /* background-color: #002A5C; */
}

.partners-title {
  font-size: 32px;
  font-weight: 600;
}

.contact-info {
  background-color: rgba(52, 218, 138, 0.15);
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@media (max-width: 992px) {
  h1 {
    font-size: 32px !important;
  }

  .banner {
    height: 75vh !important;
  }

  .mobile-margin {
    margin: 15px 0;
  }

  .navbar-collapse {
    background-color: #002A5C;
    padding: 20px;
  }

  .banner h1,
  .subpage-banner h1 {
    width: 95%;
  }
}